var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MessageView" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("TransitionSlide", [
          _c(
            "div",
            { staticClass: "text" },
            [
              _vm.nameProgram
                ? _c("h1", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("PROGRAM." + _vm.nameProgram)) + " "
                    )
                  ])
                : _vm._e(),
              _c("BaseButton", { on: { click: _vm.showResult } }, [
                _vm._v(" " + _vm._s(_vm.$t("MESSAGE_FINISH.SHOW_RESULT")) + " ")
              ])
            ],
            1
          )
        ])
      ],
      1
    ),
    _c("div", { staticClass: "content window-fit" }, [
      _c("div", { staticClass: "head-title" }, [
        _c("div", { staticClass: "title" }, [
          _c("img", {
            staticClass: "image",
            attrs: {
              src: require("@/assets/images/aztertu_completo.png"),
              alt: "logo"
            }
          }),
          _vm.nameProgram
            ? _c("p", { staticClass: "name-program" }, [
                _vm._v(" " + _vm._s(_vm.$t("PROGRAM." + _vm.nameProgram)) + " ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "content" }, [
          _vm.userReport
            ? _c("div", { staticClass: "full-name-user" }, [
                _c(
                  "p",
                  [
                    _vm._v(" " + _vm._s(_vm.fullName) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "heart-icon",
                      attrs: { size: "xs", icon: ["fas", "heart"] }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "icon" },
            [
              _c("font-awesome-icon", {
                staticClass: "icon trash",
                attrs: { size: "xs", icon: ["fas", "check"] }
              })
            ],
            1
          ),
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("MESSAGE_FINISH.TITLE")))]),
          _c("div", { staticClass: "message" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("MESSAGE_FINISH.CONTENT")))])
          ]),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c("BaseButton", { on: { click: _vm.showResult } }, [
                _vm._v(" " + _vm._s(_vm.$t("MESSAGE_FINISH.SHOW_RESULT")) + " ")
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }