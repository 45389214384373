<template>
  <div class="MessageView">
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1 v-if="nameProgram">
            {{ $t(`PROGRAM.${nameProgram}`) }}
          </h1>
          <BaseButton @click="showResult">
            {{ $t('MESSAGE_FINISH.SHOW_RESULT') }}
          </BaseButton>
        </div>
      </TransitionSlide>
    </div>

    <div class="content window-fit">
      <div class="head-title">
        <div class="title">
          <img
            class="image"
            :src="require(`@/assets/images/aztertu_completo.png`)"
            alt="logo"
          >

          <p
            v-if="nameProgram"
            class="name-program"
          >
            {{ $t(`PROGRAM.${nameProgram}`) }}
          </p>
        </div>
        <div class="content">
          <div
            v-if="userReport"
            class="full-name-user"
          >
            <p>
              {{ fullName }}
              <font-awesome-icon
                class="heart-icon"
                size="xs"
                :icon="['fas', 'heart']"
              />
            </p>
          </div>
          <div class="icon">
            <font-awesome-icon
              class="icon trash"
              size="xs"
              :icon="['fas', 'check']"
            />
          </div>

          <p> {{ $t('MESSAGE_FINISH.TITLE') }}</p>


          <div class="message">
            <p> {{ $t('MESSAGE_FINISH.CONTENT') }}</p>
          </div>

          <div class="button">
            <BaseButton @click="showResult">
              {{ $t('MESSAGE_FINISH.SHOW_RESULT') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  computed: {
    ...get('reportModule', [
      'isLoading',
      'getReport',
      'getBlocks',
      'getAnswers',
      'getProgram',
      'getDataUser',
    ]),

    nameProgram() {
      const name = this.getProgram() ?? '';
      return name.toUpperCase();
    },

    userReport() {
      return this.getDataUser();
    },

    fullName() {
      return `${this.userReport?.user?.firstName} ${this.userReport?.user?.lastName}`;
    },
  },


  async created() {
    const reportId = this.$route?.params?.reportId;
    if (reportId !== undefined) await this.$store.dispatch('reportModule/fetchReport', reportId);
    else this.$router.push({ name: 'Reports' });
  },

  methods: {
    showResult() {
      const reportId = this.$route?.params?.reportId;
      this.$router.push({ name: 'Report', params: { reportId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.MessageView {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 220px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }

    @media #{$breakpoint-lg-max} {
      .text {
        margin: 2rem auto 3rem;
      }
    }
  }
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }

    .icon {
      color: $color-primary;
      font-size: 5rem;
    }

    .icon-heart{
      color: $color-error;
      font-size: 3rem;
    }

    .head-title {
      display: flex;
      background: #fff;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin-bottom: $spacing-xl;

      padding: $spacing-m;
      color: $color-primary-dark;
      font-weight: 600;
      font-size: $font-size-3xl;
      box-shadow: $shadow-elevation-1;

      .title {
        display: flex;
        flex: 1;
        flex-wrap: wrap;


        .image {
          width: 12rem;
        }
        p {
          text-align: right;
          text-transform: uppercase;
          flex: 1;
          padding: $spacing-s;
          font-size: 28px;
          color: $color-primary-darkest;
        }
      }

      .content {
        //background: $color-neutral-lightest;

        .full-name-user{
          font-size: 2rem;
          margin-top: 1rem;

          .heart-icon{
            color: red;
          }
        }

        p:nth-child(2) {
          color: $color-primary;
          font-size: 2rem;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
        }

        .message {
          font-size: 22px;
          color: black;
          font-weight: 300;
        }

        .button {
          margin-top: 2rem;
        }
      }
    }
  }

  @media #{$breakpoint-md-max} {
    .content {
      .card {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .subheader {
      display: none;
    }
  }
}

@media print {
  .MessageView {
    background: #fff;
    .header {
      display: none;
    }
    .content {
      .card {
        grid-template-columns: repeat(1, auto);
        .block-title {
          width: 100%;
        }
      }
    }
  }
}
</style>
